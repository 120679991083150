<template>

  <!--  <div id="preloader" class="preloader" >
      <div class='inner'>
        <div class='line1'></div>
        <div class='line2'></div>
        <div class='line3'></div>
      </div>
    </div>-->
  <section class="fxt-template-animation fxt-template-layout1 align-items-start loaded">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-md-6 col-12 fxt-bg-color">
          <div class="fxt-content">
            <div class="fxt-header">
              <a href="javascript:;" class="fxt-logo w-25">
                <img :src="generalSettings.logo_url" v-if="generalSettings.logo" alt="Logo" class="">
              </a>
              <div class="fxt-page-switcher">
                <router-link to="sign-in" class="switcher-text1 active">{{ l('admin:genel:login_now') }}</router-link>
                <router-link to="sign-up" class="switcher-text1">{{ l('admin:genel:register') }}</router-link>  
              </div>
            </div>
            <div class="fxt-form">
              <h2>{{ l('admin:genel:login_now') }}</h2>
              <p>{{ l('web:transfer:devam_et_icin') }}</p>
              <form
                  class="form w-100"
                  autocomplete="off"
                  @submit="onSubmitLogin($event, form)"
              >

              <div class="form-group z-index-2">
                  <div class="fxt-transformY-50 fxt-transition-delay-4 ">
                    <vue-tel-input
                        v-if="parameters"
                        class="form-control form-control-lg form-control-solid shadow-none"
                        :preferredCountries="['TR']"
                        :default-country="defaultCountry"
                        :autoDefaultCountry="!!parameters.tum_ulkelere_gsm_izni"
                        :onlyCountries="!parameters.tum_ulkelere_gsm_izni?['TR']:[]"
                        @input="onInput"
                        v-model="form.phone"
                        v-bind="bindProps"
                        v-maska="'(###) ###-##-##'"
                        style="outline: 0"
                    />
                  </div>
                    <!--end::Input-->
                </div>
                <div class="form-group ">
                  <div class="fxt-transformY-50 fxt-transition-delay-5">
                    <input
                        class="form-control form-control-lg form-control-solid"
                        type="password"
                        name="password"
                        autocomplete="off"
                        v-model="form.password"
                    />
               <i class="fas fa-lock"></i>
                  </div>
                </div>
                <div class="form-group">
                  <div class="fxt-checkbox-area">
                    <div class="checkbox">
                      <input class="me-2" id="checkbox1" type="checkbox">
                      <label for="checkbox1" style="font-weight: normal; font-size: 15px;">{{ l('admin:genel:Beni Hatırla') }}</label> 
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="fxt-transformY-50 fxt-transition-delay-3">
                    <div class="fxt-content-between">
                      <button ref="submitButton"
                              id="kt_sign_in_submit"
                              type="submit" class="fxt-btn-fill">
                          <span class="indicator-label"> {{ l('admin:languages:languages446') }} </span>

                        <span class="indicator-progress">{{ l('admin:languages:languages433') }}
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      <router-link to="password-reset" class="switcher-text2"> {{ l('admin:genel:sifremi_unuttum') }}</router-link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="fxt-footer">
              <ul class="fxt-socials">
                <li class="fxt-facebook fxt-transformY-50 fxt-transition-delay-4">
                  <a href="https://www.facebook.com/Solife-104375132051422" title="Facebook"><i
                      class="fab fa-facebook-f"></i></a>
                </li>
                <li class="fxt-instagram fxt-transformY-50 fxt-transition-delay-5">
                  <a href="https://www.instagram.com/solifecomtr" title="instagram"><i class="fab fa-instagram"></i></a>
                </li>
                <li class="fxt-twitter fxt-transformY-50 fxt-transition-delay-6">
                  <a href="https://twitter.com/solifecom" title="twitter"><i class="fab fa-twitter"></i></a>
                </li>
                <li class="fxt-google fxt-transformY-50 fxt-transition-delay-7">
                  <a href="https://www.google.com/search?q=solife&oq=solife&aqs=chrome..69i57j0i512l9.927j0j15&sourceid=chrome&ie=UTF-8"
                     title="google"><i class="fab fa-google"></i></a>
                </li>
                <li class="fxt-linkedin fxt-transformY-50 fxt-transition-delay-8">
                  <a href="https://www.linkedin.com/company/solifecom/" title="linkedin"><i
                      class="fab fa-linkedin-in"></i></a>
                </li>
                <li class="fxt-pinterest fxt-transformY-50 fxt-transition-delay-9">
                  <a href="https://tr.pinterest.com/solifecom/" title="pinterest"><i class="fab fa-pinterest-p"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 fxt-none-767 fxt-bg-img"
             style="background-image: url(media/img/solife-img5.png)"></div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
} from 'vue';
import {Actions} from '@/store/enums/StoreEnums';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {VueTelInput} from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import toast from '@/core/functions/toast';
import {l} from '@/core/helpers/lang';
import ApiService from "@/core/services/ApiService";
import {get_country_api_url} from "@/ayar";


export default defineComponent({
  name: 'sign-in',
  components: {
    VueTelInput
  },
  data() {
    return {
      password: '',
      bindProps: {
        placeholder: l('admin:languages:langugaes434'),
        required: false,
        autocomplete: 'off',
        name: 'phone',
        autoFormat: false,
        customValidate: false,
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false,
          styleClasses: 'shadow-none'

        }
      },
    };
  },
  methods: {
    onInput(phone, phoneObject) {
      if (phoneObject && phoneObject.formatted && phoneObject.country && phoneObject.nationalNumber) {
        this.form.phone = phoneObject.country.dialCode + phoneObject.nationalNumber;
      }
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();


    const submitButton = ref<HTMLElement | null>(null);

    const form = reactive({
      phone: '',
      password: ''
    });
    const parameters = computed(() => store.getters.getParameterList);

    const onSubmitLogin = (event, values) => {
      event.preventDefault();
      store.dispatch(Actions.PURGE_AUTH);

      if (event.submitter) {
        event.submitter.setAttribute('data-kt-indicator', 'on');
      }
      setTimeout(() => {
        store
            .dispatch(Actions.LOGIN, values)
            .then(() => {
              toast(l('admin:genel:success'), true);
              router.push({name: 'ozet'});
            })
            .catch(() => {
              toast(l('user:languages:languages282'), false);
            });
        event.submitter.removeAttribute('data-kt-indicator', 'off');
      }, 2000);
    };

    const defaultCountry = ref('TR');

    ApiService.get(get_country_api_url ).then(function (payload) {
      defaultCountry.value = payload.data.country_code
    });


    return {
      onSubmitLogin,
      submitButton,
      defaultCountry,
      form,
      parameters,
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
      l
    };
  },
});
</script>
<style>
.vti__input {
  background: transparent !important;
}

.vue-tel-input:focus-within {
  border: none !important;
}
</style>